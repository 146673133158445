export default {
  errors: {
    tryLater: {
      title: {
        en: 'Oops, we’re experiencing some technical issues',
      },
      text: {
        en: 'We are currently experiencing technical issues, please try paying for your subscription later.',
      },
      primaryButton: {
        en: 'Try again',
      },
    },
    tryAgain: {
      title: {
        en: 'There may be issues with your card',
      },
      text: {
        en: 'At the moment we’re unable to charge you, check your account balance or update your payment method.',
      },
      primaryButton: {
        en: 'Update payment method',
      },
      secondaryButton: {
        en: 'Try again',
      },
    },
  },
}
